import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import img4 from '../assets/img/4.png'

//CONTEXT
import ConexContext from '../context/ConexContext'
import CursoListingMicro from "./CursoListingMicro";

export const PTGAS = () => {
	const { lang } = useParams();//parametros de la url
	const { loc, peticion }=useContext(ConexContext)//contexto
	const [categoriesMap, setcategoriesMap] = useState(null);

	useEffect(() => {
		document.title = "PTGAS";
	}, []);

	useEffect(() => {
		//pide las categorias (solo al principio)
		async function categorias_peticion() {
			const pet = await peticion('/categoriasPDI', { method: 'POST', json: {categoria: 'ptgas'}})
			if (pet.estado) //algún error
				return;
				setcategoriesMap(pet.objeto);
		}
		//pide los tipos (solo al principio)

		if (categoriesMap) return
		categorias_peticion()
	}, [categoriesMap, peticion]);

	return (
		<>
			<div className="ptgas">
				{/* <div className="col-12 volver">
					<div className="col-12 contvolver">
						<Link to={{ pathname: `/${lang}/inicio` }} alt="volver al inicio">
							{loc("Volver")}
						</Link>
					</div>
					<a href="#main-content" className="oculto">
						Saltar al contenido principal
					</a>
				</div> */}
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("PTGAS-TITULO")}</h1>
							<p>{loc("PTGAS-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img4} alt="PTGAS" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb" className="migapan gris">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-PTGAS")}
					</li>
				</ol>
			</nav>

			{categoriesMap &&
				<CursoListingMicro data={{"tipos": [{
					"label": "PTGAS Idiomas",
					"nombre": "idiomas",
					"descripcion": "En esta sección se muestran las formacion para el PTGAS de la Universidad de Jaén.",
					"fondo": "/assets/img/pool-cursos/img-50.jpg"
				}], "categorias": categoriesMap, "cursos-pdi": null}} json={true} nombre_CAT={"Categoría"}/>
			}
		</>
	);
}
