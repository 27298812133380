import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import img5 from "../assets/img/5.png";
import nombres from "../cursos-nombres.json";

//CONTEXT
import ConexContext from "../context/ConexContext";
import CursoListingMicro from "./CursoListingMicro";

export const Competencias = () => {
	const { lang } = useParams(); //parametros de la url
	const { loc, peticion } = useContext(ConexContext); //contexto
	const [categoriesMap, setcategoriesMap] = useState(null);

	useEffect(() => {
		document.title = "Competencias";
	}, []);

	useEffect(() => {
		//pide las categorias (solo al principio)
		async function categorias_peticion() {
			const pet = await peticion('/categorias', { method: 'POST' })
			if (pet.estado) //algún error
				return;
				setcategoriesMap(pet.objeto);
		}
		//pide los tipos (solo al principio)

		if (categoriesMap) return
		categorias_peticion()
	}, [categoriesMap, peticion]);

	return (
		<>
			<div className="microcredenciales ">
				<div className="col-12 contslider">
					<div className="col-12 info">
						<div className="col-6">
							<h1>{loc("COMPETENCIAS-TITULO")}</h1>
							<p>{loc("COMPETENCIAS-SUBTITULO")}</p>
						</div>
						<div className="col-6">
							<img src={img5} alt="Competencias" className="sliderimg" />
						</div>
					</div>
				</div>
			</div>

			<nav aria-label="breadcrumb " className="migapan marron">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={{ pathname: `/${lang}/inicio` }}>
							{loc("NAVBAR-INICIO")}
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{loc("NAVBAR-COMPETENCIAS")}
					</li>
				</ol>
			</nav>

			{categoriesMap &&
				<CursoListingMicro data={{"tipos": [{
					"label": "Otros",
					"nombre": "CG-CO-CP-CU-ING-JO-SI-SM-TA",
					"descripcion": "Cursos estacionales para el desarrollo territorial (verano, otoño, etc.), entendidos como actividades formativas de corta duración concebidas como un foro de discusión y transmisión de conocimiento, en el que participan especialistas tanto del ámbito académico como profesional, con la intención de fomentar el debate y reflexionar sobre temas actuales de relevancia e interés para el desarrollo territorial de la provincia de Jaén. <br/><br/>Actividades Formativas Específicas, para cuyo acceso se puede requerir o no titulación universitaria previa y son de corta duración, entre los que se incluyen: cursos, jornadas, seminarios, congresos, simposios centrados en la formación y ampliación del conocimiento académico y que llevan un reconocimiento de aprovechamiento.",
					"fondo": "/assets/img/pool-cursos/img-47.jpg"
				}], "categorias": categoriesMap, "cursos-pdi": null}} json={false} nombre_CAT={"Área de conocimiento"} json_nombres={nombres['nombres']}/>
			}
		</>
	);
};
